.--has-icon {
	vertical-align: middle;
	> * { vertical-align: middle; }
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 0;
	vertical-align: middle;
}

.icon-16 { width: 16px; height: 16px; }

.icon-18 { width: 18px; height: 18px; }
.icon-64 { width: 64px; height: 64px; }

.icon-primary { fill: theme(primary, base); }
.icon-secondary { fill: theme(secondary, base); }
.icon-highlight { fill: theme(highlight, base); }
.icon-accent { fill: theme(accent, base); }
.icon-green { fill: $green; }
.icon-blue { fill: $blue; }
.icon-orange { fill: $orange; }
.icon-purple { fill: $purple; }
.icon-white { fill: $white; }