.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 1em;
}

.logo {
	display: inline-block;
}

	.branding-logo {
		padding: 0 20px;
		background-color: $body-bg;
		svg {
			width: 200px;
			height: 97px;
		}
	}

	.page_front .branding-logo svg {
		@include media-breakpoint-up(sm) { width: 240px; height: 117px; }
	}	

	.site-info-logo {}