.page_title {
	border-top: 2px solid white;
	background-color: theme(secondary, base);
	color: $white;
	@include media-breakpoint-up(md) { padding: 90px 15px 15px; }

}

	.page-title {
		margin: 0;
		small {
			font-size: 55%;
			font-style: italic;
			font-weight: $body-weight-normal;
			letter-spacing: -0.01em;
			line-height: 1em;
			color: #ccc;
		}
	}
