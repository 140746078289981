$form_focus_border: 		$yellow;
$form_focus_bg: 			lighten($yellow, 40%);
$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form {

	font-family: $font-family-sans-serif;
	line-height: 1em;

}

	.form-title {
		margin: 0 0 10px;
		@include font-size(20);
		font-weight: $heading-weight-bold;
		small { @include font-size(15); }
	}


	.form_group {
		position: relative;
		margin: 0 0 5px;
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
		&.--has-error .form_control {
			border-color: $color-danger;
			border-width: 2px;
		}
	}

		label {
			display: block;
			margin: 10px 0 3px;
			@include font-size(15);
			font-weight: $body-weight-bold;
			line-height: 1em;
		}

		.form_control {
			display: block;
			width: 100%;
			padding: 9px 7px;
			line-height: 1em;
			border: 1px solid #ccc;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}	
		}

			textarea.form_control {
				min-height: 100px;
				line-height: 1.275em;
			}

	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 5px;
			@include font-size(12);
			font-weight: $body-weight-bolder;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}


	.form_spam {
		position: relative;
		height: 76px;
		//background-color: red;
		.g-recaptcha {
			position: absolute;
			top: 0;
			left: -20px;
			@include transform(scale(0.87));
		}
	}

	.validation-error {
		margin-top: 5px;
		padding: 5px;
		@include font-size(13);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		text-transform: uppercase;
		color: $white;
		&.--required {
			&::before {
				display: inline-block;
				width: 15px; height: 15px;
				margin-right: 5px;
				border: 2px solid $color-danger;
				vertical-align: top;
				content: "";
			}
		}
	}