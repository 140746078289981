@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #343a40; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .content .sidebar {
    flex: 0 0 37.5%;
    max-width: 37.5%; } }

@media (min-width: 1200px) {
  .content .main {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .content .sidebar {
    flex: 0 0 31.25%;
    max-width: 31.25%; } }

@media (min-width: 992px) {
  .content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .content.flip .main {
    order: 2; }
  .content.flip .sidebar {
    order: 1; } }

@media (min-width: 992px) {
  .content.flip_alt .main {
    order: 1; }
  .content.flip_alt .sidebar {
    order: 2; } }

@media (min-width: 992px) {
  .page_contact .content .content_text {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .page_contact .content .content_contact {
    flex: 0 0 68.75%;
    max-width: 68.75%; } }

@media (min-width: 992px) {
  .page_region .content .main {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .page_region .content .sidebar {
    flex: 0 0 31.25%;
    max-width: 31.25%; } }

body {
  min-width: 360px;
  background-color: #f5f5f5; }
  @media (min-width: 576px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 74px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 17px;
  font-size: 1.0625rem;
  letter-spacing: -0.0275em;
  line-height: 1.64706em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 18px;
      font-size: 1.125rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -0.0375em; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    letter-spacing: -0.0125em;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  margin: 30px 0 15px;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 17px;
      font-size: 1.0625rem; } }

h4, .h4 {
  font-size: 20px;
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 23px;
      font-size: 1.4375rem; } }

h3, .h3 {
  font-size: 24px;
  font-size: 1.5rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0475em; }

h2, .h2 {
  font-size: 28px;
  font-size: 1.75rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 35px;
      font-size: 2.1875rem; } }

h1, .h1 {
  font-size: 32px;
  font-size: 2rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 42px;
      font-size: 2.625rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 118%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #343a40; }
  blockquote.bq--primary {
    border-color: #22B573; }
  blockquote.bq--secondary {
    border-color: #736357; }
  blockquote.bq--highlight {
    border-color: #f80; }
  blockquote.bq--accent {
    border-color: #036cb8; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ba8b00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #343a40; }
  .bq--primary .bq--title {
    color: #22B573; }
  .bq--secondary .bq--title {
    color: #736357; }
  .bq--highlight .bq--title {
    color: #f80; }
  .bq--accent .bq--title {
    color: #036cb8; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #ba8b00; }
  .bq--info .bq--title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #343a40;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #22B573; }
  blockquote.bq-alt.bq--secondary {
    border-color: #736357; }
  blockquote.bq-alt.bq--highlight {
    border-color: #f80; }
  blockquote.bq-alt.bq--accent {
    border-color: #036cb8; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #036cb8; }
  a:hover, a:focus {
    color: #0489ea;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #b1a398; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

a.phone {
  text-decoration: none;
  cursor: default !important; }

.list_icon, .list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list_icon li:not(:last-child), .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .list_icon, .menu, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list-inline li:not(:last-child) {
  margin-right: 5px; }

.list_icon {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; }
  .list_icon li {
    padding-left: 2em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

.icon-16 {
  width: 16px;
  height: 16px; }

.icon-18 {
  width: 18px;
  height: 18px; }

.icon-64 {
  width: 64px;
  height: 64px; }

.icon-primary {
  fill: #22B573; }

.icon-secondary {
  fill: #736357; }

.icon-highlight {
  fill: #f80; }

.icon-accent {
  fill: #036cb8; }

.icon-green {
  fill: #42aa14; }

.icon-blue {
  fill: #0c84ea; }

.icon-orange {
  fill: #ff8400; }

.icon-purple {
  fill: #cc00ff; }

.icon-white {
  fill: #fff; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    max-width: 320px;
    margin: 0 0 15px 20px;
    max-width: none; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    max-width: 320px;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 320px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 320px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.btn {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1em;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  box-shadow: 0 10px 6px -10px rgba(0, 0, 0, 0.6);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms; }
  .btn:hover, .btn:focus {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    cursor: pointer;
    box-shadow: none; }
  .btn.btn--sm {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 8px 12px; }
  .btn.btn--lg {
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 15px 20px; }
  .btn.btn--wide {
    display: block;
    width: 100%; }
  .btn small {
    display: block; }

.--solid-primary {
  border: 1px solid #1c955f;
  background-color: #1ea065;
  color: #fff !important; }
  .--solid-primary:hover, .--solid-primary:focus {
    border-color: #47dc99;
    background-color: #26ca81;
    color: #fff !important; }

.--solid-secondary {
  border: 1px solid #5d5047;
  background-color: #64574c;
  color: #fff !important; }
  .--solid-secondary:hover, .--solid-secondary:focus {
    border-color: #9b897b;
    background-color: #826f62;
    color: #fff !important; }

.--solid-highlight {
  border: 1px solid #d97400;
  background-color: #e67a00;
  color: #fff !important; }
  .--solid-highlight:hover, .--solid-highlight:focus {
    border-color: #ffac4d;
    background-color: #ff941a;
    color: #fff !important; }

.--solid-accent {
  border: 1px solid #025692;
  background-color: #035d9f;
  color: #fff !important; }
  .--solid-accent:hover, .--solid-accent:focus {
    border-color: #0c97fb;
    background-color: #037bd1;
    color: #fff !important; }

.--solid-review {
  border: 1px solid #d98200;
  background-color: #e68a00;
  color: #fff !important; }
  .--solid-review:hover, .--solid-review:focus {
    border-color: #ffb84d;
    background-color: #ffa31a;
    color: #fff !important; }

.--solid-success {
  border: 1px solid #218838;
  background-color: #23923d;
  color: #fff !important; }
  .--solid-success:hover, .--solid-success:focus {
    border-color: #48d368;
    background-color: #2dbc4e;
    color: #fff !important; }

.--solid-danger {
  border: 1px solid #c82333;
  background-color: #d32535;
  color: #fff !important; }
  .--solid-danger:hover, .--solid-danger:focus {
    border-color: #e77681;
    background-color: #e04b59;
    color: #fff !important; }

.--solid-warning {
  border: 1px solid #e0a800;
  background-color: #edb100;
  color: #fff !important; }
  .--solid-warning:hover, .--solid-warning:focus {
    border-color: #ffd454;
    background-color: #ffc721;
    color: #fff !important; }

.--solid-info {
  border: 1px solid #0169a2;
  background-color: #0172af;
  color: #fff !important; }
  .--solid-info:hover, .--solid-info:focus {
    border-color: #18adfe;
    background-color: #0192e1;
    color: #fff !important; }

.company_block {
  margin-bottom: 15px !important;
  padding: 15px 15px 1px;
  text-align: center;
  background-color: #fff; }
  @media (min-width: 768px) and (max-width: 991px) {
    .company_block {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .company_block .company_header,
      .company_block .company_info {
        width: 50%; }
      .company_block .list_company,
      .company_block .company_gallery {
        width: 100%; }
      .company_block .company_address {
        margin-bottom: 0; }
      .company_block .list_company {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #ccc; } }
  @media (min-width: 992px) {
    .company_block {
      text-align: left; } }

.company_logo {
  display: block;
  width: 100%;
  max-width: 360px;
  margin: 0 auto 15px; }
  @media (min-width: 992px) {
    .company_logo {
      margin: 0 0 15px; } }

.call_emergency {
  display: block;
  margin-bottom: 0;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.05em;
  color: #e00; }

.company_call {
  line-height: 1.1em; }
  .company_call .phone {
    font-size: 27px;
    font-size: 1.6875rem;
    font-weight: 800;
    letter-spacing: -0.025em;
    color: #f80; }

.company_address {
  line-height: 1.25em; }
  .company_address .address_title {
    display: block; }
  @media (min-width: 992px) {
    .company_address {
      padding: 15px;
      border-left: 5px solid #ccc; } }

.list_company {
  clear: both;
  font-weight: 600; }
  .list_company li {
    display: inline-block;
    padding-left: 27px;
    line-height: 1.25em; }
    .list_company li:not(:last-child) {
      margin-right: 15px; }

@media (max-width: 991px) {
  .company_gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .company_gallery > * {
      width: 49%; } }

@media (min-width: 992px) {
  .company_gallery img {
    width: 100%; } }

.regional_form {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #fff; }
  @media (min-width: 768px) and (max-width: 991px) {
    .regional_form .form_wrapper {
      display: flex;
      flex-wrap: wrap; }
      .regional_form .form_wrapper .form-title {
        width: 100%; }
      .regional_form .form_wrapper .wrap_info {
        width: 320px; }
      .regional_form .form_wrapper .wrap_submit {
        padding-left: 20px; } }
  @media (min-width: 992px) {
    .regional_form {
      float: right;
      margin: 0 0 15px 15px;
      width: 300px; } }
  @media (min-width: 1200px) {
    .regional_form {
      width: 350px; } }

.directory_list p {
  position: relative;
  margin: 0;
  padding: 15px;
  font-size: 14px;
  font-size: 0.875rem; }
  .directory_list p:nth-child(odd) {
    background-color: #fff; }

.directory_list strong,
.directory_list em {
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  font-style: normal; }

@media (min-width: 768px) {
  .directory_list em {
    position: absolute;
    top: 15px;
    right: 15px; } }

.coverage {
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  line-height: 1.275em; }
  .coverage > *:last-child {
    margin-bottom: 0; }
  .coverage p {
    margin-bottom: 8px; }

.coverage-title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800; }

.coverage-county {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600; }

.coverage-city {
  font-size: 16px;
  font-size: 1rem; }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form-title {
  margin: 0 0 10px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700; }
  .form-title small {
    font-size: 15px;
    font-size: 0.9375rem; }

.form_group {
  position: relative;
  margin: 0 0 5px; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }
  .form_group.--has-error .form_control {
    border-color: #dc3545;
    border-width: 2px; }

label {
  display: block;
  margin: 10px 0 3px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1em; }

.form_control {
  display: block;
  width: 100%;
  padding: 9px 7px;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px;
  line-height: 1.275em; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 5px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  height: 76px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: 0;
    left: -20px;
    -webkit-transform: scale(0.87);
    -moz-transform: scale(0.87);
    -o-transform: scale(0.87);
    transform: scale(0.87); }

.validation-error {
  margin-top: 5px;
  padding: 5px;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff; }
  .validation-error.--required::before {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 2px solid #dc3545;
    vertical-align: top;
    content: ""; }

.branding-title {
  margin: 0;
  font-weight: normal;
  line-height: 1em; }

.logo {
  display: inline-block; }

.branding-logo {
  padding: 0 20px;
  background-color: #f5f5f5; }
  .branding-logo svg {
    width: 200px;
    height: 97px; }

@media (min-width: 576px) {
  .page_front .branding-logo svg {
    width: 240px;
    height: 117px; } }

.store_map {
  margin: 0 0 1em;
  border: 4px solid #fff; }

.menu {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.caret,
.caret-push {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 3px;
  vertical-align: top;
  fill: #fff; }

.caret-push {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  margin-right: 0;
  width: 0; }

.dropdown-menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px;
  background-color: #fff; }
  .dropdown-menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown-menu .menu_link:hover .caret-push, .dropdown-menu .menu_link:focus .caret-push {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    width: 10px;
    margin-right: 3px; }
  @media (min-width: 768px) {
    .dropdown-menu {
      position: absolute; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

@media (max-width: 767px) {
  .slideshow .slick-prev,
  .slideshow .slick-next {
    visibility: hidden; } }

.slider_jumbo {
  margin: 0 auto;
  line-height: 0px; }
  .slider_jumbo .slick-prev,
  .slider_jumbo .slick-next {
    display: none !important; }
  .slider_jumbo .slide_wrapper {
    position: relative; }
  .slider_jumbo .slide-placeholder {
    width: 100%; }
  .slider_jumbo .slide_caption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 5px 10px 5px 20px;
    line-height: 1em;
    background-color: #f5f5f5;
    /*         @include media-breakpoint-up(xl) {
    h2 { @include font-size(60); }
    small { @include font-size(24); }
    p { @include font-size(22); }
} */ }
    .slider_jumbo .slide_caption h2 {
      margin: 0;
      font-size: 42px;
      font-size: 2.625rem;
      line-height: 1em;
      text-transform: uppercase;
      color: #22B573; }
    .slider_jumbo .slide_caption small {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1em;
      color: #736357; }
    @media (min-width: 576px) {
      .slider_jumbo .slide_caption h2 {
        font-size: 48px;
        font-size: 3rem; }
      .slider_jumbo .slide_caption small {
        font-size: 18px;
        font-size: 1.125rem; }
      .slider_jumbo .slide_caption p {
        font-size: 17px;
        font-size: 1.0625rem; } }
    @media (min-width: 992px) {
      .slider_jumbo .slide_caption h2 {
        font-size: 55px;
        font-size: 3.4375rem; }
      .slider_jumbo .slide_caption small {
        font-size: 24px;
        font-size: 1.5rem; }
      .slider_jumbo .slide_caption p {
        font-size: 20px;
        font-size: 1.25rem; } }
  .slider_jumbo .slick-slide:nth-child(even) .slide_caption {
    left: auto;
    right: 0;
    padding: 5px 20px 5px 10px;
    text-align: right; }
  @media (min-width: 576px) {
    .slider_jumbo .slide-placeholder {
      opacity: 0; }
    .slider_jumbo .slide {
      background-size: cover; }
    .slider_jumbo .slide_1 {
      background-image: url("/_/images/slides/jumbo/1.webp"); }
    .slider_jumbo .slide_2 {
      background-image: url("/_/images/slides/jumbo/2.webp"); }
    .slider_jumbo .slide_3 {
      background-image: url("/_/images/slides/jumbo/3.webp"); }
    .slider_jumbo .slide_4 {
      background-image: url("/_/images/slides/jumbo/4.webp"); }
    .slider_jumbo .slide_5 {
      background-image: url("/_/images/slides/jumbo/5.webp"); } }

.widget {
  margin-bottom: 30px;
  padding: 30px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.275em;
  border-radius: 8px;
  border: 2px solid #ccc;
  border-left-width: 20px;
  background-color: #fff; }
  .widget.widget-green {
    border-color: #42aa14; }
    .widget.widget-green .widget-title {
      color: #42aa14; }
    .widget.widget-green a {
      color: #42aa14 !important; }
  .widget.widget-blue {
    border-color: #0c84ea; }
    .widget.widget-blue .widget-title {
      color: #0c84ea; }
    .widget.widget-blue a {
      color: #0c84ea !important; }
  .widget.widget-orange {
    border-color: #ff8400; }
    .widget.widget-orange .widget-title {
      color: #ff8400; }
    .widget.widget-orange a {
      color: #ff8400 !important; }
  .widget.widget-purple {
    border-color: #cc00ff; }
    .widget.widget-purple .widget-title {
      color: #cc00ff; }
    .widget.widget-purple a {
      color: #cc00ff !important; }
  .widget > *:first-child {
    margin-top: 0; }
  .widget > *:last-child {
    margin-bottom: 0; }

.widget-title {
  margin: 10px 0 5px;
  letter-spacing: -0.0375em; }

.widget_menu {
  border-left-width: 2px; }
  .widget_menu .widget-title {
    margin-bottom: 10px; }
  .widget_menu .menu_item:not(:last-child) {
    border-bottom: 1px dotted rgba(52, 58, 64, 0.4); }
  .widget_menu .menu_item.active > .menu_link {
    color: #22B573;
    font-weight: 800; }
    .widget_menu .menu_item.active > .menu_link .caret-push {
      width: 0;
      margin-right: 0; }
  .widget_menu .menu_link {
    padding: 10px 2px;
    font-weight: 600;
    color: #343a40; }
    .widget_menu .menu_link:hover, .widget_menu .menu_link:focus {
      color: #736357; }
      .widget_menu .menu_link:hover .caret-push, .widget_menu .menu_link:focus .caret-push {
        -webkit-transition: all ease-in-out 300ms;
        -moz-transition: all ease-in-out 300ms;
        -ms-transition: all ease-in-out 300ms;
        -o-transition: all ease-in-out 300ms;
        transition: all ease-in-out 300ms;
        width: 12px;
        margin-right: 3px; }
    .widget_menu .menu_link .caret-push {
      fill: #736357; }

.widget-abox {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1em; }
  .widget-abox > a {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 16px;
    font-size: 1rem; }
  .widget-abox p {
    margin-bottom: 0;
    line-height: 1.25em; }
  .widget-abox .phone {
    font-size: 27px;
    font-size: 1.6875rem;
    font-weight: 800; }
  .widget-abox img {
    display: block;
    width: 100%;
    max-width: 240px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget-abox {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center; }
      .widget-abox > a {
        margin: 0 15px 0 0; }
      .widget-abox img {
        max-width: 200px !important; } }

.block {
  padding: 30px 15px; }

.block_title {
  padding: 0 0 15px; }

.block-title {
  margin: 0; }

.front_featured {
  background-color: #736357; }
  @media (max-width: 991px) {
    .front_featured {
      margin-top: 30px; }
      .front_featured .main {
        margin-bottom: 30px; } }

.featured-abox {
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #22B573;
  background-color: #fff;
  box-shadow: 0 20px 15px -20px rgba(0, 0, 0, 0.7); }
  .featured-abox h2 {
    margin: 0;
    font-size: 20px;
    font-size: 1.25rem; }
  .featured-abox .phone {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 800;
    color: #f80 !important; }
  .featured-abox .fa-logo {
    display: block;
    margin: 0 0 15px; }
  .featured-abox ul {
    line-height: 1.25em; }
    .featured-abox ul li:nth-child(1) b,
    .featured-abox ul li:nth-child(2) b {
      font-size: 115%; }
  .featured-abox h3 {
    font-size: 18px;
    font-size: 1.125rem;
    margin: 0 0 5px; }
  .featured-abox .coverage {
    border: none;
    border-top: 1px solid #ccc;
    padding: 15px 0 0; }
  @media (min-width: 768px) {
    .featured-abox .fa-logo {
      float: right;
      margin: -45px 0 15px 15px; } }

.block_banner {
  padding: 20px 15px;
  text-align: center;
  background-image: linear-gradient(to bottom, transparent 49.5%, #ccc 49.5%, #ccc 50.5%, transparent 50.5%); }
  @media (min-width: 768px) {
    .block_banner {
      background-image: linear-gradient(to bottom, transparent 49.6%, #ddd 49.6%, #ddd 50.4%, transparent 50.4%); } }

.page_title {
  border-top: 2px solid white;
  background-color: #736357;
  color: #fff; }
  @media (min-width: 768px) {
    .page_title {
      padding: 90px 15px 15px; } }

.page-title {
  margin: 0; }
  .page-title small {
    font-size: 55%;
    font-style: italic;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1em;
    color: #ccc; }

.block_main.content {
  background-color: #f5f5f5;
  /* 	padding: 0;
.container {
	padding: 30px;
	background-color: $body-bg;
} */ }

@media (min-width: 992px) {
  .main .form-title {
    margin-top: 0; } }

.main > *:last-child {
  margin-bottom: 0; }

.page_contact .main .form {
  padding: 15px;
  background-color: #fff; }
  .page_contact .main .form .form-title {
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    .page_contact .main .form {
      margin-top: 30px; } }

.sidebar .form {
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #fff; }

.sidebar > *:last-child {
  margin-bottom: 0; }

.block_coverage {
  text-align: center;
  border-top: 1px dotted #ccc;
  background-color: #fff; }
  .block_coverage .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block_coverage .wrap {
      flex: 0 0 62.5%;
      max-width: 62.5%; } }
  @media (min-width: 1200px) {
    .block_coverage .wrap {
      flex: 0 0 56.25%;
      max-width: 56.25%; } }

.site_info {
  padding-bottom: 20px;
  background-color: #16754a;
  color: #fff; }
  .site_info a {
    color: #fff; }

.front_jumbo {
  padding: 0; }
  .front_jumbo .jumbo_marketing {
    order: 1; }
  .front_jumbo .jumbo_conversion {
    order: 3; }
  .front_jumbo .jumbo_services {
    order: 2; }
  @media (max-width: 991px) {
    .front_jumbo .row {
      margin: 0; }
    .front_jumbo .container {
      padding: 0;
      max-width: none; }
    .front_jumbo .jumbo_marketing {
      padding: 0 20px; } }
  @media (min-width: 768px) {
    .front_jumbo .jumbo_marketing {
      flex: 0 0 68.75%;
      max-width: 68.75%;
      padding-right: 0; }
    .front_jumbo .jumbo_services {
      flex: 0 0 31.25%;
      max-width: 31.25%; } }
  @media (min-width: 992px) {
    .front_jumbo {
      padding: 0 30px; }
      .front_jumbo .jumbo_marketing {
        order: 2;
        padding-left: 15px; }
      .front_jumbo .jumbo_conversion {
        order: 1;
        flex: 0 0 31.25%;
        max-width: 31.25%; }
      .front_jumbo .jumbo_services {
        order: 3;
        flex: 0 0 100%;
        max-width: 100%; } }

.jumbo_marketing {
  padding: 0; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .jumbo_marketing .carousel {
      padding: 20px 0; } }

.jumbo_conversion {
  background-color: #736357;
  color: #fff; }
  .jumbo_conversion .form {
    padding: 15px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .jumbo_conversion {
      margin-top: 20px; }
      .jumbo_conversion .form_wrapper {
        display: flex;
        flex-wrap: wrap; }
        .jumbo_conversion .form_wrapper .form-title {
          width: 100%; }
        .jumbo_conversion .form_wrapper .wrap_info {
          width: 320px; }
        .jumbo_conversion .form_wrapper .wrap_submit {
          padding-left: 20px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .jumbo_conversion .form {
      padding: 15px 0 0; } }
  @media (min-width: 1200px) {
    .jumbo_conversion .form {
      padding: 25px 10px; } }

.jumbo_services {
  margin: 15px 0;
  padding: 0 20px; }
  .jumbo_services ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .jumbo_services li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 33.33333%;
    padding: 15px;
    margin: 0 !important;
    line-height: 1em;
    text-align: center;
    background-color: #22B573;
    color: #fff; }
    .jumbo_services li:nth-child(1) {
      background-color: #24be78; }
    .jumbo_services li:nth-child(2) {
      background-color: #1ea065; }
    .jumbo_services li:nth-child(3) {
      background-color: #1a8a58; }
    .jumbo_services li:nth-child(4) {
      background-color: #16754a;
      width: 50%; }
    .jumbo_services li:nth-child(5) {
      background-color: #125f3c;
      width: 50%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .jumbo_services {
      margin: 0;
      padding-left: 0; }
      .jumbo_services ul {
        height: 100%; }
      .jumbo_services li {
        width: 100% !important;
        margin: 0;
        height: 20%; } }
  @media (min-width: 992px) {
    .jumbo_services {
      padding: 0; }
      .jumbo_services li {
        width: 20% !important;
        padding: 30px 15px; } }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 700 !important; }

.hw2 {
  font-weight: 700 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #343a40 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #22B573 !important; }

.color--secondary {
  color: #736357 !important; }

.color--highlight {
  color: #f80 !important; }

.color--accent {
  color: #036cb8 !important; }

.color--link {
  color: #036cb8 !important; }

.color--review {
  color: #f90 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #343a40 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #22B573 !important; }

.bg--secondary {
  background-color: #736357 !important; }

.bg--highlight {
  background-color: #f80 !important; }

.bg--link {
  background-color: #036cb8 !important; }

.bg--review {
  background-color: #f90 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f5f5f5 !important; }

.gdfancybg--text {
  background: #343a40;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #343a40; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #22B573;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #22B573; }

.gdfancybg--secondary {
  background: #736357;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #736357; }

.gdfancybg--highlight {
  background: #f80;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #f80; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }
