.front_jumbo {

	padding: 0;

	.jumbo_marketing { order: 1; }
	.jumbo_conversion { order: 3; }
	.jumbo_services { order: 2; }

	@include media-breakpoint-down(md) {
		.row { margin: 0; }
		.container { padding: 0; max-width: none; }
		.jumbo_marketing { padding: 0 20px;	}
	}

	@include media-breakpoint-up(md) {
		.jumbo_marketing { 
			@include make-col(11);
			padding-right: 0;
		}
		.jumbo_services { @include make-col(5); }
	}

	@include media-breakpoint-up(lg) {
		padding: 0 30px;
		.jumbo_marketing { order: 2; padding-left: 15px; }
		.jumbo_conversion { order: 1; @include make-col(5); }
		.jumbo_services { order: 3; @include make-col(16); }
	}

}

	.jumbo_marketing {
		padding: 0;
		@include media-breakpoint-only(lg) {
			.carousel {
				padding: 20px 0;
			}
		}
	}

	.jumbo_conversion {
		background-color: $color-secondary;
		color: $white;
		.form { padding: 15px; }
		@include media-breakpoint-only(md) {
			margin-top: 20px;
			.form_wrapper {
				@include make-flex;
				.form-title { width: 100%; }
				.wrap_info {
					width: 320px;
				}
				.wrap_submit { padding-left: 20px; }
			}
		}
		@include media-breakpoint-only(lg) {
			.form { padding: 15px 0 0; }
		}
		@include media-breakpoint-up(xl) {
			.form { padding: 25px 10px; }
		}
	}

	.jumbo_services {
		margin: 15px 0;
		padding: 0 20px;
		ul {
			@include make-flex;
			justify-content: center;
		}
		li {
			@include make-flex;
			align-items: center;
			justify-content: center;
			width: percentage(1/3);
			padding: 15px;
			margin: 0 !important;
			line-height: 1em;
			text-align: center;
			background-color: $color-primary;
			color: $white;
			&:nth-child(1) { background-color: lighten($color-primary, 2%); }
			&:nth-child(2) { background-color: darken($color-primary, 5%); }
			&:nth-child(3) { background-color: darken($color-primary, 10%); }
			&:nth-child(4) {
				background-color: darken($color-primary, 15%);
				width: percentage(1/2);
			}
			&:nth-child(5) {
				background-color: darken($color-primary, 20%);
				width: percentage(1/2);
			}
		}
		@include media-breakpoint-only(md) {
			margin: 0; padding-left: 0;
			ul { height: 100%; }
			li {
				width: 100% !important;
				margin: 0;
				height: 20%;
			}
		}
		@include media-breakpoint-up(lg) {
			padding: 0;
			li {
				width: percentage(1/5) !important;
				padding: 30px 15px;
			}
		}
	}
