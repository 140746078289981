@mixin btn-solid($bg: $color-link) {
	border: 1px solid darken($bg, 7.5%);
	background-color: darken($bg, 5%);
	color: $white !important;
	&:hover, &:focus {
		border-color: lighten($bg, 15%);
		background-color: lighten($bg, 5%);
		color: $white !important;
	}
}

.btn {
	display: inline-block;
	padding: 10px 15px;
	@include font-size(16);
	font-weight: $heading-weight-bold;
	line-height: 1em;
	text-decoration: none;
	border-radius: 4px;
	border: 1px solid;
	box-shadow: 0 10px 6px -10px rgba(black, 0.6);
	
	@include transform(scale(0.95));
	@include transition(all 200ms);

	&:hover, &:focus {
		@include transform(scale(1));
		@include transition(all 200ms);
		cursor: pointer;
		box-shadow: none;
	}

	&.btn--sm {
		@include font-size(15);
		padding: 8px 12px;
	}

	&.btn--lg {
		@include font-size(17);
		padding: 15px 20px;
	}

	&.btn--wide {
		display: block;
		width: 100%;
	}

	small { display: block; }

}

	.--solid-primary { @include btn-solid($color-primary); }
	.--solid-secondary { @include btn-solid($color-secondary); }
	.--solid-highlight { @include btn-solid($color-highlight); }
	.--solid-accent { @include btn-solid($color-accent); }
	.--solid-review { @include btn-solid($color-review); }
	.--solid-success { @include btn-solid($color-success); }
	.--solid-danger { @include btn-solid($color-danger); }
	.--solid-warning { @include btn-solid($color-warning); }
	.--solid-info { @include btn-solid($color-info); }

