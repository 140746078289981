.block {
	//border: 1px solid;
	padding: 30px 15px;
}

.block_front {}

	.block_title {
		padding: 0 0 15px;
	}

		.block-title {
			margin: 0;
		}

	.block_media {}

	.block_text {}

	.block_more {}


.front_featured {
	background-color: $color-secondary;
	@include media-breakpoint-down(md) {
		margin-top: 30px;
		.main { margin-bottom: 30px; }
	}
}

	.featured-abox {
		padding: 20px;
		border-radius: 6px;
		border: 3px solid $color-primary;
		background-color: $white;
  		box-shadow: 0 20px 15px -20px rgba(0, 0, 0, 0.7);
		h2 {
			margin: 0;
			@include font-size(20);
		}
		.phone {
			@include font-size(24);
			font-weight: $body-weight-bolder;
			color: $color-highlight !important;
		}
		.fa-logo {
			display: block;
			margin: 0 0 15px;
		}
		ul {
			line-height: 1.25em;
			li:nth-child(1),
			li:nth-child(2) {
				b { font-size: 115%; }
			}
		}
		h3 { @include font-size(18); margin: 0 0 5px; }

		.coverage {
			border: none;
			border-top: 1px solid #ccc;
			padding: 15px 0 0;
		}

		@include media-breakpoint-up(md) {
			.fa-logo { float: right; margin: -45px 0 15px 15px; }
		}
	}