.sidebar {

	.form {
		margin-bottom: 30px;
		padding: 15px;
		border: 1px solid #ddd;
		background-color: $white;
	}
	
	>*:last-child { margin-bottom: 0; }

}