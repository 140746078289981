@mixin limbo {
	li { margin-bottom: 0 !important; }
}

%list, .list {
	margin: 0 0 1em;
	padding: 0 0 0 1.2em;	
	li:not(:last-child) { margin-bottom: 0.25em; }
}

		@mixin list {
			margin: 0 0 1em;
			padding: 0 0 0 1.2em;	
			li:not(:last-child) { margin-bottom: 0.25em; }		
		}

%list_unstyled, .list-unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
		
		@mixin list_unstyled {
			margin-left: 0;
			padding-left: 0;
			list-style-type: none;
			list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);		
		}

%list_inline, .list-inline {
	@extend %list_unstyled;
	li { display: inline-block; }
	li:not(:last-child) { margin-right: 5px; }
}

		@mixin list_inline {
			@include list_unstyled;
			li { display: inline-block; }
		}

.list_icon {

	@extend %list;
	@extend %list_unstyled;
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;

	li {
		padding-left: 2em;
		font-size: 1.125rem;
		background-repeat: no-repeat;
		background-size: 1.25rem;
		background-position: left center;
	}

}

	.icon_check-primary li { background-image: url("/_/images/icons/cm_primary.svg") }
	.icon_check-secondary li { background-image: url("/_/images/icons/cm_secondary.svg") }
	.icon_check-success li { background-image: url("/_/images/icons/cm_success.svg") }
