.menu {
	@extend %list_unstyled;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

	.menu_item {
		box-sizing: border-box;
		list-style-type: none;

		&.active .menu_link {
			cursor: default;
			&:hover { cursor: default; }
		}

		&.open {
			.dropdown_menu {
				display: block;
				height: auto;
			}
			.caret {
				@include transform(rotate(180deg));
			}
		}

	}

		.menu_link {
			@include transition(all ease-in-out 300ms);
			display: block;
			padding: 12px 15px;
			line-height: 1em;
			text-decoration: none;
			&:hover,
			&:focus {
				@include transition(all ease-in-out 300ms);
				outline: none;
			}
		}

			.caret,
			.caret-push {
				position: relative;
				display: inline-block;
				width: 10px;
				height: 10px;
				top: 3px;
				vertical-align: top;
				fill: $white;
			}

			.caret-push {
				@include transform(rotate(-90deg));
				@include transition(all ease-in-out 300ms);
				margin-right: 0;
				width: 0;
			}

		.dropdown-menu {

			@include transition(all ease-in-out 300ms);
			display: none;
			position: relative;
			z-index: 95000;
			margin: 0;
			padding: 1em;
			min-width: 280px;
			background-color: $white;

			.menu_item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				text-align: left;
			}

				.menu_link {
					&:hover, &:focus {
						.caret-push {
							@include transition(all ease-in-out 300ms);
							width: 10px;
							margin-right: 3px;
						}
					}				
				}

			@include media-breakpoint-up(md) {
				position: absolute;
			}

		}



