.block_banner {
	padding: 20px 15px;
	text-align: center;

	background-image: linear-gradient(
		to bottom,
		transparent 49.5%,
		#ccc 49.5%,
		#ccc 50.5%,
		transparent 50.5%
	);

	@include media-breakpoint-up(md) {
		background-image: linear-gradient(
			to bottom,
			transparent 49.6%,
			#ddd 49.6%,
			#ddd 50.4%,
			transparent 50.4%
		);
	}

}