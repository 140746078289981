.company_block {
	margin-bottom: 15px !important;
	padding: 15px 15px 1px;
	text-align: center;
	background-color: $white;

	@include media-breakpoint-only(md) {

		@include make-flex;
		align-items: center;
		.company_header,
		.company_info { width: percentage(1/2); }
		.list_company,
		.company_gallery { width: 100%; }

		.company_address { margin-bottom: 0; }

		.list_company {
			margin-top: 15px;
			padding-top: 15px;
			border-top: 1px solid #ccc;
		}

	}

	@include media-breakpoint-up(lg) {
		text-align: left;
	}
}

	.company_logo {
		display: block;
		width: 100%;
		max-width: 360px;
		margin: 0 auto 15px;
		@include media-breakpoint-up(lg) {
			margin: 0 0 15px;
		}
	}

	.call_emergency {
		display: block;
		margin-bottom: 0;
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		letter-spacing: -0.05em;
		color: #e00;
	}

	.company_call {
		line-height: 1.1em;
		.phone {
			@include font-size(27);
			font-weight: $body-weight-bolder;
			letter-spacing: -0.025em;
			color: $color-highlight;
		}
	}

	.company_address {
		line-height: 1.25em;
		.address_title { display: block; }
		@include media-breakpoint-up(lg) {
			padding: 15px;
			border-left: 5px solid #ccc;
		}
	}

	.list_company {
		clear: both;
		font-weight: 600;
		li {
			display: inline-block;
			padding-left: 27px;
			line-height: 1.25em;
			&:not(:last-child) { margin-right: 15px; }
		}
	}

	.company_gallery {
		@include media-breakpoint-down(md) {
			@include make-flex;
			justify-content: space-between;
			> * { width: 49%; }
		}
		@include media-breakpoint-up(lg) {
			img { width: 100%; }
		}
	}

.regional_form {
	margin-bottom: 15px;
	padding: 15px;
	border: 1px solid #ddd;
	background-color: $white;

	@include media-breakpoint-only(md) {
		.form_wrapper {
			@include make-flex;
			.form-title { width: 100%; }
			.wrap_info {
				width: 320px;
			}
			.wrap_submit { padding-left: 20px; }
		}
	}

	@include media-breakpoint-up(lg) {
		float: right;
		margin: 0 0 15px 15px;
		width: 300px;
	}

	@include media-breakpoint-up(xl) {
		width: 350px;
	}

}


.directory_list {

	p {
		position: relative;
		margin: 0;
		padding: 15px;
		@include font-size(14);
		&:nth-child(odd) { background-color: $white; }
	}

	strong,
	em {
		display: block;
		@include font-size(18);
		font-weight: $body-weight-bold;
		font-style: normal;
	}

	@include media-breakpoint-up(md) {
		em {
			position: absolute;
			top: 15px; right: 15px;
		}
	}

}