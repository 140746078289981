.widget-abox {

	@include font-size(20);
	line-height: 1em;

	> a {
		display: inline-block;
		margin-bottom: 10px;
		@include font-size(16);
	}

	p { margin-bottom: 0; line-height: 1.25em; }

	.phone {
		@include font-size(27);
		font-weight: $body-weight-bolder;
	}

	img {
		display: block;
		width: 100%;
		max-width: 240px;
	}

	@include media-breakpoint-only(md) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		> a { margin: 0 15px 0 0; }
		img { max-width: 200px !important; }
	}

}