.block_main.content {
	background-color: $body-bg;
/* 	padding: 0;
.container {
	padding: 30px;
	background-color: $body-bg;
} */
}

.main {

	@include media-breakpoint-up(lg) {
		.form-title { margin-top: 0; } 
	}


	>*:last-child { margin-bottom: 0; }

}

.page_contact .main .form {
	padding: 15px;
	background-color: $white;
	
	.form-title { margin-bottom: 20px; }

	@include media-breakpoint-down(md) {
		margin-top: 30px;
	}

}