.block_coverage {
	text-align: center;
	border-top: 1px dotted #ccc;
	background-color: $white;

	.row { justify-content: center; }

	@include media-breakpoint-up(lg) { .wrap {@include make-col(10); } }

	@include media-breakpoint-up(xl) { .wrap {@include make-col(9); } }

}
