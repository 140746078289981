.coverage {
	
	padding: 15px;
	border: 1px solid #ddd;
	background-color: $white;

	line-height: 1.275em;
	> *:last-child { margin-bottom: 0; }
	p { margin-bottom: 8px; }

}


	.coverage-title {
		margin-top: 0;
		margin-bottom: 8px;
		@include font-size(18);
		font-weight: $body-weight-bolder;
	}

	.coverage-county {
		@include font-size(16);
		font-weight: $body-weight-bold;
	}

	.coverage-city {
		@include font-size(16);
	}