@mixin widget-color($color: #333) {
	border-color: $color;
	.widget-title { color: $color; }
	a { color: $color !important; }
}

.widget {
	margin-bottom: 30px;
	padding: 30px;
	@include font-size(16);
	line-height: 1.275em;
	border-radius: 8px;
	border: 2px solid #ccc;
	border-left-width: 20px;
	background-color: $white;

	&.widget-green { @include widget-color($green); }
	&.widget-blue { @include widget-color($blue); }
	&.widget-orange { @include widget-color($orange); }
	&.widget-purple { @include widget-color($purple); }

	> *:first-child { margin-top: 0; }

	> *:last-child { margin-bottom: 0; }

}

	.widget-title {
		margin: 10px 0 5px;
		letter-spacing: -0.0375em;
	}
